<template>
    <div>
        <div class="h3 mb-5 text-center">Transactions</div>
        <b-card class="card-large">
            <div class="card-content">
                <b-row>
                    <b-col md="5" class="filters">
                        <div class="caption">
                            Filter by asset
                        </div>
                        <b-form-row>
                            <b-col>
                                <b-input placeholder="Token’s name" v-model="searchToken"></b-input>
                            </b-col>
                            <b-col class="flex-grow-0">
                                <button class="button-flat" v-on:click="filter()">Search</button>
                            </b-col>
                        </b-form-row>
                        <div class="caption mt-3">
                            Total amount of pools: {{ totalAmount }}
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col>
                        <div class="filter-badge">
                            <div class="filter-badge-item active">All</div>
                            <div class="filter-badge-item">Swap</div>
                            <div class="filter-badge-item">Add</div>
                            <div class="filter-badge-item">Withdraw</div>
                        </div>
                    </b-col>
                </b-row>
                    <b-row class="mt-4">
                        <b-col>
                            <TransactionsList
                                    :existingFields = "existingTransactionsFields"
                                    :transactions = "tableDataBuild()"
                                    scroll>
                            </TransactionsList>
                        </b-col>
                    </b-row>

            </div>
        </b-card>
    </div>

</template>

<script>

    export default {
        name: "Transactions",
        components: {
            TransactionsList: () => import("@/components/dashboard/TransactionsList"),
        },
        computed: {
            isMobile() {
                return window.innerWidth <= 768;
            }
        },
        data: function () {
            return {
                existingTransactionsFields: [
                    {key: 'type', thClass: 'caption'},
                    {key: 'totalValue', thClass: 'caption'},
                    {key: 'amount1', thClass: 'caption', label: 'Token amount'},
                    {key: 'amount2', thClass: 'caption', label: 'Token amount'},
                    {key: 'account', thClass: 'caption'},
                    {key: 'time', thClass: 'caption'},
                ],
                getTransactionsList: [
                    {
                        id: '',  timestamp: '1604120946', transactionType: 'Swap',
                        deposits: [],
                        withdrawals: [],
                        simpleSwaps: [{amountUSD: '425.97', amount1: '26.591', amount2: '4.723', pair: { token1: 'ELF', token2: 'SASHIMI' }, from: '0x123c4fe...'}],
                        delayedSwaps: [],
                    },
                    {
                        id: '',  timestamp: '1604142770', transactionType: 'Add',
                        deposits: [{amountUSD: '720.59', amount1: '20', amount2: '30.000', pair: { token1: 'ETH', token2: 'MOFI' }, from: '0x123c4fe...'}],
                        withdrawals: [],
                        simpleSwaps: [],
                        delayedSwaps: [],
                    }
                ],

                totalAmount: 0,
                searchToken: '',

            }
        },
        methods: {
            tableDataBuild: function () {
               return  this.getTransactionsList.map(function (item) {
                   switch (item.transactionType) {
                        case "Add":
                            Object.assign(item, item.deposits[0])
                            break
                        case "Swap":
                            Object.assign(item, item.simpleSwaps[0])
                            break
                        case "Withdraw":
                            Object.assign(item, item.withdrawals[0])
                            break
                        case "OrderPlaced":
                            Object.assign(item, item.delayedSwaps[0])
                            break
                        case "OrderClaimed":
                            Object.assign(item, item.delayedSwaps[0])
                            break
                    }
                   return item
                })
            }
        },
    }
</script>

<style scoped lang="scss">

</style>
